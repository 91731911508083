import { FormMap } from "src/types/form-map";
import { EMAIL_PATTERN } from "./patern";

export const adminForm: FormMap[] = [
  {
    formName: "name",
    label: "Full Name",
    type: "text",
    rules: [
      {
        required: true,
        max: 100,
      },
    ],
    placeholder: "Enter Member Name",
  },
  {
    formName: "email",
    label: "Email",
    type: "text",
    rules: [
      {
        required: true,
        max: 100,
      },
      {
        pattern: EMAIL_PATTERN,
        message: "Invalid email",
      },
    ],
    placeholder: "Enter Member Email",
  },
  {
    formName: "admin_role_id",
    label: "Add member role",
    type: "select",
    rules: [
      {
        required: true,
        message: "Please select role",
      },
    ],
    placeholder: "Select Member Role",
    options: [],
  },
];

export enum AdminRoleAuthorityTab {
  USER_MANAGEMENT = "user-management",
  DASHBOARD_CONFIG = "dashboard-config",
  PARAMETERS = "parameters",
  TRI_MEMBER = "tri-member",
  FINANCES = "finances",
  OPERATIONS = "operations",
  CUSTOMER_SUPPORT = "customer-support",
  DIRECTOR = "director",
}
