import { UploadResponse } from "src/types";
import { HttpClient } from "./http-service";
import { MediaDirectoryEnum } from "src/constants";

interface Dict<T> {
  [key: string]: T;
  [key: number]: T;
}

export interface ChangeListener {
  (event: any): any;
}

export class ServiceBase extends HttpClient {
  private onChangeListeners: Dict<ChangeListener> = {};

  get = async (url: string, params?: any): Promise<any> => {
    const response = await this.axiosInstance.get(url, { params });
    return response;
  };

  put = async (url: string, data: any): Promise<any> => {
    const response = await this.axiosInstance.put(url, data);
    return response;
  };

  patch = async (url: string, data: any): Promise<any> => {
    const response = await this.axiosInstance.patch(url, data);
    return response;
  };

  post = async (url: string, params: any): Promise<any> => {
    const response = await this.axiosInstance.post(url, params);
    return response;
  };

  delete = async (url: string, id: number | string): Promise<any> => {
    const response = await this.axiosInstance.delete(`${url}/${id}`);
    return response;
  };

  deleteByUrl = async (url: string): Promise<any> => {
    const response = await this.axiosInstance.delete(url);
    return response;
  };

  update = async (
    url: string,
    id: number | undefined,
    params: any
  ): Promise<any> => {
    const response = await this.axiosInstance.patch(`${url}/${id}`, params);
    return response;
  };

  subscribe(key: string, listener: ChangeListener) {
    if (this.onChangeListeners[key]) return;
    this.onChangeListeners[key] = listener;
  }

  unsubcribe(key: string) {
    delete this.onChangeListeners[key];
  }

  fire(data: any) {
    Object.values(this.onChangeListeners).forEach((listener) => listener(data));
  }

  uploadImage = async (
    file: any,
    folder: MediaDirectoryEnum
  ): Promise<UploadResponse> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("folder", folder);
    const response = await this.axiosInstance.post("storage/upload", formData);
    return response.data;
  };

  downloadFile = async (url: string) => {
    this.axiosInstance.defaults.responseType = "blob";
    const res = await this.axiosInstance.get(url);

    this.axiosInstance.defaults.responseType = "json";
    return res;
  };
}
