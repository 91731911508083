import { Form, FormItemProps, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { BaseOptionType } from "antd/lib/select";
import React, { Fragment } from "react";
import { removeExtraSpace } from "src/helpers";
import { addTwoDecimal } from "src/helpers/input";
import {
  decimalOnlyInput,
  filterECharacterInputDecimal,
  filterECharacterInputNumber,
  integerOnlyInput,
} from "src/helpers/integerOnly";
import CustomInput from "src/pages/productManagement/CustomInput/CustomInput";
import CustomPhoneInput from "../07.inputs/CustomPhoneInput";
import CustomTextarea from "../07.inputs/CustomTextarea";
import CustomCheckbox from "../09.checkbox/CustomCheckbox";
import RadioBoxList from "../23.radioBoxList/RadioBoxList";
import CustomImageInput from "../25.customImageInput/CustomImageInput";
import VideoInput from "../28.VideoInput/VideoInput";
import CustomDropImageInput from "../29.CustomDropImageInput/CustomDropImageInput";

interface Props {
  arrayField: {
    label: string;
    formName: string;
    placeholder: string;
    rules: FormItemProps["rules"];

    type:
      | "text"
      | "number"
      | "select"
      | "checkbox"
      | "ratio"
      | "date"
      | "time"
      | "textarea"
      | "image"
      | "file"
      | "phoneNumber"
      | "video"
      | "imageDrop"
      | "hidden";
    options?: { label: string; value: string | number }[];
    optionsRatio?: { label: string; value: string | boolean }[];
    isDecimal?: boolean;
    isSingle?: boolean;
    multiple?: number;
    isEdit?: boolean;
    description?: string;
    prefix?: React.ReactNode | string;
    suffix?: React.ReactNode | string;
    onChange?: (value?: any) => void;
    tooltip?: string;
    readonly?: boolean;
  }[];
  form: FormInstance;
  isFormFilled: boolean;
}
const FormMap: React.FC<Props> = ({ arrayField, form, isFormFilled }) => {
  const filterOption = (search: string, option?: BaseOptionType) => {
    if (!option) return false;
    const regex = new RegExp(search, "i");
    return regex.test(option.label);
  };

  return (
    <>
      {arrayField.map((field, idx: number) => {
        if (field.type === "text") {
          return (
            <Fragment key={idx}>
              <CustomInput
                readOnly={field.readonly}
                tooltip={field.tooltip}
                suffix={field.suffix}
                prefix={field.prefix}
                name={field.formName}
                label={field.label}
                placeholder={field.label}
                onBlur={(e) => removeExtraSpace(e, form, field.formName)}
                rules={field.rules}
                onChange={(e) => {
                  field.onChange && field.onChange(e);
                }}
              />
            </Fragment>
          );
        }
        if (field.type === "number") {
          return (
            <Fragment key={idx}>
              <CustomInput
                tooltip={field.tooltip}
                readOnly={field.readonly}
                type="number"
                suffix={field.suffix}
                prefix={field.prefix}
                name={field.formName}
                label={field.label}
                placeholder={field.label}
                onBlur={(e) => {
                  removeExtraSpace(e, form, field.formName);
                  if (field.formName === "min_order") {
                    addTwoDecimal(field.formName, form, e);
                  }
                }}
                onKeyDown={
                  field.isDecimal ? decimalOnlyInput : integerOnlyInput
                }
                onChange={(e) => {
                  field.isDecimal
                    ? filterECharacterInputDecimal(field.formName, form, e)
                    : filterECharacterInputNumber(field.formName, form, e);

                  field.onChange && field.onChange(e);
                }}
                rules={field.rules}
              />
            </Fragment>
          );
        }
        if (field.type === "checkbox") {
          return (
            <Fragment key={idx}>
              <CustomCheckbox
                name={field.formName}
                label={field.label}
                rules={field.rules}
                options={field.options}
                onChange={(e) => {
                  field.onChange && field.onChange(e);
                }}
              />
            </Fragment>
          );
        }
        if (field.type === "select") {
          return (
            <Fragment key={idx}>
              <Form.Item
                name={field.formName}
                label={field.label}
                rules={field.rules}
                labelCol={{ span: 24 }}
              >
                <Select
                  placeholder={field.placeholder}
                  options={field.options}
                  showSearch
                  filterOption={filterOption}
                  allowClear
                  onChange={(e) => {
                    field.onChange && field.onChange(e);
                  }}
                />
              </Form.Item>
            </Fragment>
          );
        }
        if (field.type === "textarea") {
          return (
            <Fragment key={idx}>
              <CustomTextarea
                name={field.formName}
                label={field.label}
                placeholder={field.label}
                onBlur={(e) => removeExtraSpace(e, form, field.formName)}
                rules={field.rules}
                showCount={true}
                maxLength={3000}
                onChange={(e) => {
                  field.onChange && field.onChange(e);
                }}
              />
            </Fragment>
          );
        }
        if (field.type === "ratio") {
          return (
            <Fragment key={idx}>
              <RadioBoxList
                name={field.formName}
                label={field.label}
                rules={field.rules}
                options={field.optionsRatio}
                onChange={(e) => {
                  field.onChange && field.onChange(e);
                }}
              />
            </Fragment>
          );
        }
        if (field.type === "image") {
          return (
            <Fragment key={idx}>
              <CustomImageInput
                name={field.formName}
                label={field.label}
                square
                isSingle={field.isSingle}
                multiple={field.multiple}
                onError={(err) =>
                  form?.setFields([{ name: field.formName, errors: [err] }])
                }
                rules={field.rules}
                isEdit={field.isEdit}
                form={form}
                isFormFilled={isFormFilled}
                description={field.description}
              />
            </Fragment>
          );
        }
        if (field.type === "imageDrop") {
          return (
            <Fragment key={idx}>
              <CustomDropImageInput
                name={field.formName}
                label={field.label}
                square
                isSingle={field.isSingle}
                multiple={field.multiple}
                onError={(err) =>
                  form?.setFields([{ name: field.formName, errors: [err] }])
                }
                rules={field.rules}
                isEdit={field.isEdit}
                form={form}
                isFormFilled={isFormFilled}
                description={field.description}
              />
            </Fragment>
          );
        }

        if (field.type === "phoneNumber") {
          return (
            <Fragment key={idx}>
              <div>
                <CustomPhoneInput
                  formInstance={form}
                  name={field.formName}
                  label={field.label}
                  isRequired={true}
                  onChange={(e: any) => {
                    filterECharacterInputNumber(field.formName, form, e);
                  }}
                  onChangeRegion={() => {}}
                  rules={field.rules}
                  style={{ marginBottom: 0 }}
                />
              </div>
            </Fragment>
          );
        }

        if (field.type === "video") {
          return (
            <Fragment key={idx}>
              <VideoInput
                rules={field.rules}
                form={form}
                name={field.formName}
                label={field.label}
                isEdit={field.isEdit ? field.isEdit : false}
                isFormFilled={isFormFilled}
              />
            </Fragment>
          );
        }

        if (field.type === "hidden") {
          return (
            <Fragment key={idx}>
              <Form.Item
                rules={field.rules}
                name={field.formName}
                label={""}
                style={{ display: "none" }}
              >
                <input type="hidden" />
              </Form.Item>
            </Fragment>
          );
        }
      })}
    </>
  );
};

export default FormMap;
