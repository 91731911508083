import React, { useCallback } from "react";
import { Input, InputProps } from "antd";
import { debounce } from "lodash";
import "./styles.scss";
import { formatTextPlural } from "src/helpers";
const { Search } = Input;
interface IInputSearch extends Omit<InputProps, "name"> {
  handleChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  debounceTime: number;
  searchResultCount: number;
}

const InputSearch: React.FC<IInputSearch> = (props) => {
  const {
    placeholder,
    handleChangeSearch,
    debounceTime,
    searchResultCount,
    ...InputProps
  } = props;

  const handleDebounceChange = useCallback(
    debounce(handleChangeSearch, debounceTime),
    [handleChangeSearch]
  );

  const handleChangeInternal = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleDebounceChange(e);
    },
    [handleDebounceChange]
  );
  return (
    <>
      <Search
        allowClear
        placeholder={placeholder}
        onChange={handleChangeInternal}
        enterButton
        {...InputProps}
      />
      <span className="input__result">
        Show{" "}
        <strong className="input__result--count">{searchResultCount}</strong>{" "}
        {formatTextPlural("result", searchResultCount)}
      </span>
    </>
  );
};

export default InputSearch;
