import { CheckboxGroupProps } from "antd/lib/checkbox";
import { FormInstance } from "antd";
import React, { useEffect } from "react";

const useCheckIsFormEdit = (formAccountInformation: FormInstance) => {
  const [isAnyEditForm, setIsAnyEditForm] = React.useState(false);
  const [isAnyFormChange, setIsAnyFormChange] = React.useState<
    (string | number)[]
  >([]);

  function handleCheckIsFormEdit() {
    const formValue = formAccountInformation.getFieldsValue();

    const isEdit = Object.keys(formValue).some((key: string) => {
      return (
        formValue[key as keyof typeof formValue] !== undefined &&
        formValue[key as keyof typeof formValue].trim() !== "" &&
        formValue[key as keyof typeof formValue] !== null
      );
    });
    setIsAnyEditForm(isEdit);
  }

  function handleCheckIsFieldChange(
    fieldName: string,
    initialValue: string | number
  ) {
    const newValue = formAccountInformation.getFieldValue(fieldName);
    const isChange = initialValue !== newValue;

    if (isChange && !isAnyFormChange.includes(fieldName)) {
      setIsAnyFormChange((prev) => [...prev, fieldName]);
    } else {
      setIsAnyFormChange((prev) => {
        const data = [...prev];
        return data.filter((field) => field !== fieldName);
      });
    }
  }

  return {
    isAnyEditForm,
    handleCheckIsFormEdit,
    setIsAnyEditForm,
    handleCheckIsFieldChange,
    isAnyFormChange,
    setIsAnyFormChange,
  };
};

export default useCheckIsFormEdit;
