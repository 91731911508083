import dayjs from "dayjs";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { PATHS } from "src/constants/paths";
import { STATUS_CODE } from "src/constants/status-code";
import { addTableIndex } from "src/helpers";
import { AdminNotificationEventName } from "src/pages/notification";
import { NotificationServices } from "src/services/notification-service";
import { PageParams } from "src/services/params-type";
import {
  decreaseNotificationCount,
  increaseNotificationCount,
  setCurrentListNotifications,
} from "src/store/actions/notification";

const useTencentNotifications = () => {
  const [params, setParams] = useState<PageParams>({
    page: 0,
    perPage: 10,
    paginationMetadataStyle: "body",
  });
  const [page, setPage] = useState(1);

  const [metadata, setMetadata] = useState<PaginationMetadata>({
    "x-next-page": 0,
    "x-page": 0,
    "x-pages-count": 0,
    "x-per-page": 0,
    "x-total-count": 0,
  });
  const listNotifications: Notification[] = useSelector(
    (state: any) => state.notification.notifications
  );
  const notificationService = new NotificationServices();
  const history = useHistory();
  const dispatch = useDispatch();
  function handleReceiveNewNotifcation(message: { data: TencentMessage[] }) {
    const newListNoti = [...listNotifications];
    const formatNotification = JSON.parse(message.data[0].payload.text);
    newListNoti.unshift(formatNotification);
    newListNoti.pop();
    const dataTable = addTableIndex(
      newListNoti,
      metadata["x-per-page"],
      metadata["x-page"]
    );
    dispatch(increaseNotificationCount());
    dispatch(setCurrentListNotifications(dataTable));
  }

  const redirectRoute = (item: any) => {
    const path = item?.meta?.eventName;
    if (
      path === AdminNotificationEventName.CategoryCreated ||
      path === AdminNotificationEventName.CategoryUpdated ||
      path === AdminNotificationEventName.CategoryDeleted
    ) {
      history.push(PATHS.categoryAndAttribute());
    } else if (
      path === AdminNotificationEventName.ConsumerActivated ||
      path === AdminNotificationEventName.ConsumerDeactivated
    ) {
      history.push(PATHS.consumer());
    } else if (path === AdminNotificationEventName.DashboardConfigUpdated) {
      history.push(PATHS.dashboardConfig());
    } else if (path === AdminNotificationEventName.ParametersConfigUpdated) {
      history.push(PATHS.parameters());
    } else if (
      path === AdminNotificationEventName.ProductHidden ||
      path === AdminNotificationEventName.ProductUnhidden
    ) {
      history.push(PATHS.productManagement());
    } else {
      history.push(PATHS.default());
    }
  };
  async function handleReadNotifiFromTencent(notiRead: any, idx: number) {
    if (!notiRead.id) {
      const newListNoti: any = await loadNotifications();
      const findNotiIdx = newListNoti.findIndex(
        (noti: any) =>
          new Date(noti.notify_at).getTime() ===
          new Date(notiRead.notify_at).getTime()
      );
      console.log(findNotiIdx);
      if (findNotiIdx === idx) {
        if (newListNoti[findNotiIdx].id) {
          console.log("read noti tencent");
          // await handleReadNotification(newListNoti[findNotiIdx], idx);
          await handleReadNotifiFromTencentAfter(newListNoti, newListNoti[findNotiIdx]);
        } else {
          console.log("no id");
          return;
        }
      } else {
        console.log("not match");
        return;
      }
    }
  }
  async function handleReadNotifiFromTencentAfter(
    listNoti: Notification[],
    item: Notification
  ) {
    try {
      const updateListNotification = listNoti.map((notification) => {
        if (notification.id === item.id) {
          return { ...notification, read_at: dayjs().toISOString() };
        }
        return notification;
      });
      console.log("list after update", updateListNotification);
      dispatch(setCurrentListNotifications(updateListNotification));
      dispatch(decreaseNotificationCount());
      await notificationService.readNotification(item.id);
    } catch (error) {
      console.log(error);
    }
  }
  async function handleReadNotification(item: Notification, idx: number) {
    if (!item.id) {
      console.log("start read from tencent");
      handleReadNotifiFromTencent(item, idx);
    } else {
      try {
        if (dayjs(item.read_at).isValid()) {
        } else {
          console.log("list before update", listNotifications);
          const updateListNotification = listNotifications.map(
            (notification) => {
              if (notification.id === item.id) {
                return { ...notification, read_at: dayjs().toISOString() };
              }
              return notification;
            }
          );
          console.log("list after update", updateListNotification);
          dispatch(setCurrentListNotifications(updateListNotification));
          dispatch(decreaseNotificationCount());
          await notificationService.readNotification(item.id);
          console.log("Read success");
        }
        // redirectRoute(item);
      } catch (error) {
        console.log({ error });
      }
    }
  }

  const loadNotifications = async () => {
    try {
      const res = await notificationService.listNotification(params);
      if (res.data?.data && res.status === STATUS_CODE.SUCCESS) {
        const { data, metadata } = res.data as PaginationData<Notification>;
        const dataTable = addTableIndex(
          data,
          metadata["x-per-page"],
          metadata["x-page"]
        );
        setMetadata(metadata);
        dispatch(setCurrentListNotifications(dataTable));
        return dataTable;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePagination = (page: number, pageSize: number) => {
    setParams({
      ...params,
      page,
      perPage: pageSize,
    });
    setPage(page);
  };

  return {
    handleReceiveNewNotifcation,
    handleReadNotification,
    loadNotifications,
    params,
    page,
    metadata,
    handleChangePagination,
  };
};

export default useTencentNotifications;
