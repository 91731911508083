import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { STATUS_CODE } from "src/constants/status-code";
import { NotificationServices } from "src/services/notification-service";
import { setCurrentNotificationCount } from "../actions/notification";
import {
  APPEND_NEW_NOTIFICATION,
  CLEAR_CURRENT_NOTIFICATION_COUNT,
  CURRENT_NOTIFICATION_COUNT,
  DECREASE_NOTIFICATION_COUNT,
  INCREASE_NOTIFICATION_COUNT,
  SET_CURRENT_LIST_NOTIFICATION,
} from "../constants/notification";

interface NotificationState {
  count: number;
  notifications: Notification[];
}
const initialState: NotificationState = {
  count: 0,
  notifications: [],
};

export const notificationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CLEAR_CURRENT_NOTIFICATION_COUNT:
      return { ...state, count: 0 };

    case CURRENT_NOTIFICATION_COUNT:
      const { count } = action.payload;
      return { ...state, count };

    case INCREASE_NOTIFICATION_COUNT:
      return { ...state, count: state.count + 1 };

    case DECREASE_NOTIFICATION_COUNT:
      return { ...state, count: state.count - 1 };

    case SET_CURRENT_LIST_NOTIFICATION:
      return {
        ...state,
        notifications: action.payload,
      };

    case APPEND_NEW_NOTIFICATION:
      const newListNoti = [...state.notifications];
      newListNoti.pop();
      newListNoti.unshift(action.payload);
      return {
        ...state,
        notifications: newListNoti,
      };

    default:
      return state;
  }
};

export const fetchListUnread = () => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const notiService = new NotificationServices();

    try {
      const res = await notiService.countUnread();

      if (res.data?.unread > -1 && res.status === STATUS_CODE.SUCCESS) {
        dispatch(setCurrentNotificationCount(res.data?.unread));
      }
    } catch (err) {}
  };
};
