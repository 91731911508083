import {
  CURRENT_NOTIFICATION_COUNT,
  CLEAR_CURRENT_NOTIFICATION_COUNT,
  INCREASE_NOTIFICATION_COUNT,
  DECREASE_NOTIFICATION_COUNT,
  SET_CURRENT_LIST_NOTIFICATION,
  APPEND_NEW_NOTIFICATION,
} from "../constants/notification";

export const clearCurrentNotification = () => {
  return {
    type: CLEAR_CURRENT_NOTIFICATION_COUNT,
  };
};

export const setCurrentNotificationCount = (count: string | number) => {
  return {
    type: CURRENT_NOTIFICATION_COUNT,
    payload: { count },
  };
};

export const increaseNotificationCount = () => {
  return {
    type: INCREASE_NOTIFICATION_COUNT,
  };
};

export const decreaseNotificationCount = () => {
  return {
    type: DECREASE_NOTIFICATION_COUNT,
  };
};

export const setCurrentListNotifications = (notifications: any) => {
  return {
    type: SET_CURRENT_LIST_NOTIFICATION,
    payload: notifications,
  };
};

export const appendNewNotification = (notification: any) => {
  return {
    type: APPEND_NEW_NOTIFICATION,
    payload: notification,
  };
};
