import {
  AppstoreAddOutlined,
  DatabaseOutlined,
  DeploymentUnitOutlined,
  FileTextOutlined,
  LogoutOutlined,
  MailOutlined,
  NotificationOutlined,
  ScheduleOutlined,
  UsergroupDeleteOutlined,
} from "@ant-design/icons";
import { ReconIcon } from "../assets/icons";
import { PATHS } from "./paths";

export const MENU_KEYS = {
  MERCHANT: "merchant",
  STORE: "store",
  DASHBOARD: "dashboard",
  TRANSACTION: "transaction",
  BALANCE: "balance",
  PERFORMANCE: "performance",
  CONFIGURATION: "configuration",
  INVESTMENT_MANAGEMENT: "investment-management",
  CATEGORY_AND_ATTRIBUTE: "category-and-attribute",
  PRODUCT_MANAGEMENT: "product-management",
  DASHBOARD_CONFIG: "dashboard-config",
  RECONCILIATION: "settlement-report",
  PARAMETERS: "parameters",
  EMAIL_TEMPLATE: "email-template",
  REPORT: "report",
  NOTIFICATION: "notification",
  LP_MANAGEMENT: "lp-management",
  MEMBER: "member",
  LOGISTIC_FEE: "logistic-fee",
  ROLES: "roles",
  CREATE_STORE: "create-store",
  CREATE_MERCHANT: "create-merchant",
  CREATE_PRODUCT: "create-product",
  EDIT_PRODUCT: "edit-product",
  EDIT_STORE: "edit-store",
  MERCHANT_LEVEL: "merchant-level",
  PLATFORM_FEE_MANAGEMENT: "fee-management",
  REFUND: "refund",
  LISTORDER: "list-orders",
};

export const MENUS_LABEL = {
  USER_MANAGEMENT: "User management",
  CATEGORY_AND_ATTRIBUTE: "Category & Attribute",
};

export const SUB_KEY = {
  TRANSACTION: [{ key: "merchant", label: "Merchant", path: "merchant" }],
};

export const DEEP_MENU_ITEMS = [
  {
    name: "Merchant & Store management",
    key: "user-management",
    breadcrumb: [""],
    children: [
      { key: "merchant", label: "Merchant", path: "merchant", level: 2 },
      {
        key: "lp-management",
        label: "LP Management",
        path: "lp-management",
        level: 2,
      },
      { key: "consumer", label: "Consumer", path: "consumer", level: 2 },
      { key: "store", label: "Store", path: "store", level: 2 },
      {
        key: "store-onboarding",
        label: "Store onboard detail",
        path: "store-onboarding",
        level: 2,
      },
      {
        key: "store-update",
        label: "Store update detail",
        path: "store-update",
        level: 2,
      },
    ],
  },

  {
    name: "Notification",
    key: "notification",
    children: [],
  },
  {
    name: "Category & Attribute",
    key: "category-and-attribute",
    breadcrumb: [""],
    children: [
      {
        key: "category",
        label: "Category",
        path: "category",
        level: 2,
      },
      {
        key: "attribute",
        label: "Attribute",
        path: "attribute",
        level: 2,
      },
    ],
  },
  {
    name: "Product Management",
    key: "product-management",
    children: [],
  },
  {
    name: "Dashboard Config",
    key: "dashboard-config",
    children: [
      {
        key: "config-menu",
        label: "What’s on the menu?",
        path: "config-menu",
        level: 2,
      },
      {
        key: "config-featured-restaurants",
        label: "Featured Restaurant",
        path: "config-featured-restaurants",
        level: 2,
      },
      {
        label: "Top Selling Dishes",
        key: "config-top-selling-dishes",
        path: "config-top-selling-dishes",
        level: 2,
      },
    ],
  },
  {
    name: "Parameters",
    key: "parameters",
    children: [],
  },
  {
    name: "Email Template",
    key: "email-template",
    children: [],
  },
  {
    name: "Settlement report",
    key: "settlement-report",
    children: [],
  },
  {
    name: "Report",
    key: "report",
    children: [],
  },
  {
    name: "Trifood Member",
    key: "tri-member",
    breadcrumb: [""],
    children: [
      { key: "member", label: "Member Management", path: "member", level: 2 },
      { key: "role", label: "Role management", path: "role", level: 2 },
    ],
  },
  {
    name: "Logistic Fee",
    key: "logistic-fee",
    children: [],
  },
];

export const MENU_ITEMS = {
  MERCHANT_AND_STORE_MANAGEMENT: {
    key: "user-management",
    label: "Merchant & Store Management",
    level: 1,
    icon: <UsergroupDeleteOutlined />,
    children: [
      {
        key: "merchant-management",
        label: "Merchant management",
        path: PATHS.merchant(),
        level: 2,
        children: [
          {
            key: "/merchant",
            label: "Merchant",
            path: PATHS.merchant(),
            level: 3,
          },
          // {
          //   key: "/lp-management",
          //   label: "LP Management",
          //   path: PATHS.lpCashOut(),
          //   level: 3,
          // },
          {
            key: "/merchant-level",
            label: "Merchant Level Fee",
            path: PATHS.merchantLevel(),
          },
        ],
      },
      {
        key: "/store",
        label: "Store management",
        path: PATHS.store(),
        level: 2,
        children: [
          { key: "/store", label: "Store", path: PATHS.store(), level: 3 },
          {
            key: "/product-management",
            label: "Product Management",
            path: PATHS.productManagement(),
            level: 3,
          },
        ],
      },
    ],
  },
  SETTLEMENT_REPORT: {
    key: "finances",
    label: "Settlement Report",
    path: PATHS.reconciliation(),
    level: 1,
    icon: <UsergroupDeleteOutlined />,
    children: [
      {
        key: "/settlement-report",
        label: "Settlement Report",
        path: PATHS.reconciliation(),
        level: 2,
      },
    ],
  },
  PLATFORM_MANAGEMENT: {
    key: "dashboard-config",
    label: "Platform Management",
    level: 1,
    icon: <UsergroupDeleteOutlined />,
    children: [
      {
        key: "/dashboard-config",
        label: "Dashboard config",
        level: 2,
        icon: <AppstoreAddOutlined />,
        path: PATHS.dashboardConfig(),
      },
      {
        key: "/category-and-attribute",
        label: "Category & Attribute",
        level: 2,
        icon: <DeploymentUnitOutlined />,
        path: PATHS.categoryAndAttribute(),
      },
      {
        key: "/report",
        label: "Report",
        level: 2,
        icon: <FileTextOutlined />,
        path: PATHS.report(),
      },
    ],
  },
  SYSTEM_SETUP: {
    key: "parameters",
    label: "System Setup",
    level: 1,
    icon: <UsergroupDeleteOutlined />,
    children: [
      {
        key: "/parameters",
        label: "Parameters",
        level: 2,
        icon: <ScheduleOutlined />,
        path: PATHS.parameters(),
      },
      // {
      //   key: "/email-template",
      //   label: "Email Template",
      //   level: 2,
      //   icon: <MailOutlined />,
      //   path: PATHS.emailTemplate(),
      // },
      // {
      //   key: "/logistic-fee",
      //   label: "Logistic Fee",
      //   level: 2,
      //   icon: <UsergroupDeleteOutlined />,
      //   path: PATHS.logisticFee(),
      // },
      {
        key: "/fee-management",
        label: "Fee Management",
        level: 2,
        icon: <DatabaseOutlined />,
        path: PATHS.platformFeeManagement(),
      },
    ],
  },
  REFUND: {
    key: "customer-support",
    label: "Customer Support",
    level: 1,
    icon: <UsergroupDeleteOutlined />,
    children: [
      {
        key: "/refund",
        label: "Refund List",
        level: 2,
        icon: <ScheduleOutlined />,
        path: PATHS.refund(),
      },
      {
        key: "/list-orders",
        label: "List Order ",
        icon: <ScheduleOutlined />,
        path: PATHS.listOrders(),
      },
    ],
  },
  TRI_MEMBER: {
    key: "tri-member",
    label: "User management",
    level: 1,
    icon: <UsergroupDeleteOutlined />,
    children: [
      {
        key: "/member",
        label: "Member Management",
        path: PATHS.member(),
        level: 2,
      },
      { key: "/role", label: "Role management", path: PATHS.role(), level: 2 },
    ],
  },
  LOGOUT: {
    key: "logout",
    label: "Logout",
    level: 1,
    icon: <LogoutOutlined />,
  },
};
