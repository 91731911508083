import TencentCloudChat, { ChatSDK } from "@tencentcloud/chat";
import { useEffect, useState } from "react";
import globalConfig from "src/config";
import { MessageType } from "src/constants";
import { NotificationServices } from "src/services/notification-service";
import useTencentNotifications from "./useTencentNotifications";

interface TencentMessages {
  (userID?: string): {
    userID?: string;
    userSig?: string;
    chat?: ChatSDK;
  };
}

const useInitTencentMessages: TencentMessages = (userID) => {
  const notificationServices = new NotificationServices();
  const [chat, setChat] = useState<ChatSDK>();
  const [userSig, setUserSig] = useState();
  const { handleReceiveNewNotifcation } = useTencentNotifications();
  async function getUserSig() {
    const res = await notificationServices.getAdminSignatureToNoti();

    if (res.data.userSig) {
      setUserSig(res.data.userSig);
    }
    return res.data.userSig;
  }

  async function createTencentChat(userSig: string): Promise<ChatSDK> {
    return new Promise((resolve, reject) => {
      let options = {
        SDKAppID: Number(globalConfig.chatAppId),
      };

      const chat = TencentCloudChat.create(options);

      const onReady = () => {
        resolve(chat);
      };
      chat.on(TencentCloudChat.EVENT.SDK_READY, onReady);
      chat.setLogLevel(4);
      chat.login({
        userID: `admin-${userID}`,
        userSig: userSig,
      });
    });
  }

  function handleListenMessageFromTencent() {
    chat?.on(
      TencentCloudChat.EVENT.MESSAGE_RECEIVED,
      (message: { data: TencentMessage[] }) => {
        if (message.data[0].conversationType === MessageType.Notifications) {
          handleReceiveNewNotifcation(message);
        }
      }
    );
  }

  async function getChatObject() {
    try {
      const userSig = await getUserSig();
      const chat = await createTencentChat(userSig);
      setChat(chat);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (!userID) return;
    getChatObject();
  }, [userID]);

  useEffect(() => {
    if (chat) {
      handleListenMessageFromTencent();
    }
  }, [chat]);
  return {
    userID,
    userSig,
    chat,
  };
};

export default useInitTencentMessages;
