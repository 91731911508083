import React, { ReactElement, useEffect, useState } from "react";
import styles from "./CustomImageInput.module.scss";
import {
  Form,
  FormInstance,
  FormItemProps,
  Image,
  Modal,
  Spin,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import { getBase64 } from "src/helpers/getBase64";
import CustomIcon from "../24.customIcon/CustomIcon";
import { IMAGE_TYPE_ALLOW } from "../../constants";
import { IMAGE } from "src/constants/messages";
import { LoadingOutlined } from "@ant-design/icons";
import { UploadFileStatus } from "antd/es/upload/interface";
import ImgCrop from "antd-img-crop";
import { isArray } from "lodash";
import { RcFile } from "antd/lib/upload";
import { GalleryAddIcon, PreviewIcon, RemoveIcon } from "src/assets";

interface Props extends Omit<UploadProps, "multiple"> {
  label: React.ReactNode;
  name: string;
  onError?: (err: string) => void;
  multiple?: boolean | number;
  square?: boolean;
  rules?: FormItemProps["rules"];
  uploadClassName?: string;
  description?: React.ReactNode;
  isSingle?: boolean;
  isEdit?: boolean;
  isCheckRation?: boolean;
  form: FormInstance;
  setIsMatchRatio?: React.Dispatch<React.SetStateAction<boolean[]>>;
  isMatchRatio?: boolean[];
  isFormFilled?: boolean;
}

const { JPEG, JPG, PNG, SVG } = IMAGE_TYPE_ALLOW;
const ACCEPTEDS = [JPEG, JPG, PNG];

const antIcon = (
  <LoadingOutlined style={{ fontSize: 36, color: "#fff" }} spin />
);

const CustomDropImageInput: React.FC<Props> = (props) => {
  const {
    className,
    uploadClassName,
    onError,
    name,
    label,
    description,
    multiple,
    rules,
    square,
    isSingle = false,
    disabled = false,
    isEdit = false,
    form,
    isCheckRation = false,
    setIsMatchRatio,
    isMatchRatio = [],
    isFormFilled,
    ...uploadProps
  } = props;
  const [isTooLarge, setIsTooLarge] = useState(false);
  const [isNotAllowType, setIsNotAllowType] = useState(false);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<any[]>([]);
  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleDelete = async (file: UploadFile) => {
    if (typeof file === "string" || (isEdit && file.uid.includes("https://"))) {
      const idxOfFileDeleted = fileList.findIndex(
        (item) => item.uid === file.uid
      );
      const newFileList =
        fileList?.filter((item: any) => item.uid !== file.uid) || [];
      const newFileListForm =
        (form
          .getFieldValue([name])
          ?.filter((item: any) => item !== file.name) as File[]) || [];
      setFileList(newFileList);
      if (isCheckRation) {
        handTestImgRationWhenEdit(idxOfFileDeleted);
      }
      form.setFieldValue([name], newFileListForm);
    } else {
      const idxOfFileDeleted = fileList.findIndex(
        (item) => item.uid === file.uid
      );
      const newFileList = fileList.filter((item) => item.uid !== file.uid);
      let newFileListForm;
      if (isArray(form.getFieldValue([name]))) {
        newFileListForm = form
          .getFieldValue([name])
          ?.filter((item: any) => item.uid !== file.uid) as File[];
      } else {
        newFileListForm = {};
      }
      setFileList(newFileList);
      if (isCheckRation) {
        handTestImgRationWhenEdit(idxOfFileDeleted);
      }
      form.setFieldValue([name], newFileListForm);
    }
  };

  function handTestImgRationWhenEdit(idxOfImg: number) {
    setIsMatchRatio &&
      setIsMatchRatio((prev) => {
        const newIsMatch = prev.filter((_, idx) => idx !== idxOfImg);
        if (newIsMatch.every((item) => item === true)) {
          form.setFields([{ name: [name], errors: undefined }]);
        } else {
          onError?.(IMAGE.NOT_MATCH_RATIO);
          form.setFields([{ name: [name], errors: [IMAGE.NOT_MATCH_RATIO] }]);
        }
        return newIsMatch;
      });
  }

  function testImgRatio(file: any) {
    const img = document.createElement("img");
    img.classList.add("hide");
    img.src = URL.createObjectURL(file.originFileObj as any);
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      const imgRation = Math.round((width / height) * 100) / 100;
      if (imgRation < 1.4 || imgRation > 1.6) {
        setIsMatchRatio?.((prev) => {
          if (prev.length === multiple) return prev;
          return [...prev, false];
        });
        onError?.(IMAGE.NOT_MATCH_RATIO);
        form.setFields([{ name: [name], errors: [IMAGE.NOT_MATCH_RATIO] }]);
      } else {
        setIsMatchRatio?.((prev) => {
          if (prev.length === multiple) return prev;
          return [...prev, true];
        });
      }

      if (
        isMatchRatio.length > 0 &&
        !isMatchRatio.every((item) => item === true)
      ) {
        onError?.(IMAGE.NOT_MATCH_RATIO);
        form.setFields([{ name: [name], errors: [IMAGE.NOT_MATCH_RATIO] }]);
      }
    };
  }

  function testImgTypeAndSize(file: any) {
    const { type, size } = file as UploadFile<File>;
    const allowType = ACCEPTEDS.includes(type || "");
    const allowSize = size && size < 1024 * 1024 * 2;

    return { allowType, allowSize };
  }

  function handleStateAfterTestFile(
    allowSize: number | boolean | undefined,
    allowType: boolean
  ) {
    if (!allowSize) {
      setIsTooLarge(true);
    } else {
      setIsTooLarge(false);
    }
    if (!allowType) {
      setIsNotAllowType(true);
    } else {
      setIsNotAllowType(false);
    }
  }

  function handleSetFileFormWhenPass(file: any, setFieldValue: any) {
    if (!isSingle) {
      const fileQuatity = form.getFieldValue([name])?.length || 0;
      if (multiple && fileQuatity === multiple) {
        return;
      }

      setFieldValue(
        [name],
        [...form.getFieldValue([name]), file.originFileObj]
      );
    } else {
      setFieldValue([name], file.originFileObj);
    }
  }

  function handleSetFileWhenPass(file: any, setFieldValue: any) {
    form.setFields([{ name: [name], errors: undefined }]);
    if (file.status === "uploading") {
      setFileList((prev) => {
        return [...prev, file];
      });
    }
    //this is because when we upload file, it will return twice, one is uploading, one is done
    if (file.status === "done") {
      setFileList((prev) => {
        const newFileList = [...prev, file].filter(
          (item) => item.status === "done"
        );
        return newFileList;
      });

      handleSetFileFormWhenPass(file, setFieldValue);
    }
  }

  const handleChange: any = async (
    { file }: { file: any },
    setFieldValue: any
  ) => {
    if (isCheckRation) {
      testImgRatio(file);
    }

    const { allowSize, allowType } = testImgTypeAndSize(file);
    handleStateAfterTestFile(allowSize, allowType);

    if (allowType && allowSize) {
      handleSetFileWhenPass(file, setFieldValue);
    }
  };

  const required = rules?.find((item) => (item as any)?.required);

  const dummyRequest = (option: any) => {
    setTimeout(() => {
      option.onSuccess("ok");
    }, 0);
  };

  useEffect(() => {
    if (!isFormFilled) return;

    if (isEdit) {
      const storeUploadFiles: string[] | string = form?.getFieldValue(name);
      if (Array.isArray(storeUploadFiles)) {
        const convertedArray: UploadFile[] = storeUploadFiles.map(
          (fileUrl, index) => {
            const uploadFile = {
              uid: fileUrl,
              name: fileUrl,
              status: "done" as UploadFileStatus,
              url: fileUrl,
              thumbUrl: fileUrl,
            };
            return uploadFile;
          }
        );
        setFileList(convertedArray);
        form.setFieldValue([name], [...storeUploadFiles]);
      } else {
        setFileList([
          {
            uid: storeUploadFiles,
            name: storeUploadFiles,
            status: "done" as UploadFileStatus,
            url: storeUploadFiles,
            thumbUrl: storeUploadFiles,
          },
        ]);
        form.setFieldValue([name], [storeUploadFiles]);
      }
    }
  }, [isEdit, form, name, isFormFilled]);

  return (
    <Form.Item dependencies={[name]} noStyle>
      {({ setFieldValue }) => {
        return (
          <>
            <Form.Item
              className={`${styles.group} ${required ? styles.required : ""} ${
                className ?? ""
              }`}
              label={
                label && (
                  <div className={styles.label}>
                    {label}
                    <div className={styles.description}>
                      {"Click to upload PNG, JPG (max. 2MB)"}
                      <br />
                      {description}
                    </div>
                  </div>
                )
              }
              rules={rules}
              labelCol={{ span: 6 }}
            >
              <ImgCrop showGrid={true} showReset aspect={1}>
                <Upload
                  disabled={disabled}
                  customRequest={dummyRequest}
                  className={`${styles.upload} ${square ? styles.square : ""} ${
                    uploadClassName ?? ""
                  }`}
                  maxCount={
                    !multiple ? 1 : multiple === true ? Infinity : multiple
                  }
                  listType="picture-card"
                  accept={ACCEPTEDS.join(",")}
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={(file) => {
                    return handleChange(file, setFieldValue);
                  }}
                  {...uploadProps}
                  itemRender={(originNode: ReactElement, file: UploadFile) => {
                    return (
                      <>
                        <div
                          className={`${styles.pictureRender} ${
                            square ? `${styles.square}` : `${styles.upload}`
                          }`}
                        >
                          <div className={styles.actions}>
                            <div
                              className={styles.actionIcon}
                              onClick={() => handlePreview(file)}
                            >
                              <PreviewIcon />
                            </div>
                            <div
                              className={styles.actionIcon}
                              onClick={() => {
                                if (!disabled) handleDelete(file);
                              }}
                            >
                              <RemoveIcon />
                            </div>
                          </div>
                          {(file.status === "done" && (
                            <Image
                              src={file.thumbUrl}
                              height={"100%"}
                              width={"100%"}
                              preview={false}
                            />
                          )) || (
                              <Image
                                src={file.thumbUrl}
                                height={"100%"}
                                width={"100%"}
                                preview={false}
                              />
                            ) || <Spin indicator={antIcon} />}
                        </div>
                      </>
                    );
                  }}
                >
                  {fileList.length < +(multiple || 1) && (
                    <CustomIcon
                      icon={GalleryAddIcon}
                      width={64}
                      fill="currentColor"
                      title={"Upload"}
                    />
                  )}
                </Upload>
              </ImgCrop>
              {isTooLarge && (
                <span className={`${styles.errorForm}`}>
                  Image cannot exceed 2MB
                </span>
              )}
              {isNotAllowType && (
                <span className={`${styles.errorForm}`}>
                  Image type is not allowed
                </span>
              )}
            </Form.Item>
            <Modal
              open={previewOpen}
              footer={null}
              onCancel={handleCancel}
              centered={true}
              width={720}
            >
              <img
                alt="imgPreview"
                style={{ width: "100%", height: "100%" }}
                src={previewImage}
              />
            </Modal>

            <Form.Item name={name} rules={rules} className={styles.error}>
              <input type="hidden" />
            </Form.Item>
          </>
        );
      }}
    </Form.Item>
  );
};

export default CustomDropImageInput;
