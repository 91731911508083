import { PATHS } from "src/constants/paths";

export function handleNavigateBreadCrumb(
  path: string,
  item: string,
  search: string,
  history: any,
  location: any
) {
  if (path === "report") {
    if (item === "Report") {
      history.push(`/report`);
    }
    if (
      location.pathname.split("/").length === 3 &&
      search &&
      item === "Merchant"
    ) {
      const urlParams = new URLSearchParams(window.location.search);
      const merchant_id = urlParams.get("merchant_id");
      history.push(`/report/merchant/${merchant_id}`);
    }
  }

  if (path.includes("merchant")) {
    if (item === "User Management" || item === "Merchant") {
      history.push(`/merchant`);
    }
  }

  if (path.includes("consumer")) {
    if (item === "User Management" || item === "Consumer") {
      history.push(`/consumer`);
    }
  }

  if (path.includes("store")) {
    switch (item) {
      case "User Management":
        history.push(`/store`);
        break;
      case "Store":
        history.push(`/store`);
        break;
      case "Store onboard detail":
        const storeOnboardId = location.pathname.split("/").at(-1);
        history.push(
          PATHS.storeOnboardDetail().replace(":id", storeOnboardId!)
        );
        break;
      case "Store update detail":
        const storeUpdateId = location.pathname.split("/").at(-1);
        history.push(PATHS.storeUpdateDetail().replace(":id", storeUpdateId!));
        break;
      default:
        history.push(`/store`);
        break;
    }
  }

  if (path.includes("category")) {
    if (item === "Category & Attribute") {
      history.push(PATHS.categoryAndAttribute());
    }
  }

  if (path.includes("config")) {
    if (item === "Dashboard Config") {
      history.push(PATHS.dashboardConfig());
    }
  }

  if (path.includes("settlement-report")) {
    history.push(PATHS.reconciliation());
  }

  if (path.includes("email-template")) {
    history.push(PATHS.emailTemplate());
  }
}
