import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps, useLocation } from "react-router";
import { ADMIN_PERMISSION } from "src/constants";
import { PATHS } from "src/constants/paths";
import { hasStorageJwtToken } from "src/helpers/storage";
import { ReduxStore } from "src/types/globalStore";

export const PrivateRoute: React.FC<RouteProps> = (props) => {
  const isLogined = hasStorageJwtToken();
  // const location = useLocation();
  // const { pathname } = location;
  // console.log({ pathname });
  // const listAdminPermission = useSelector(
  //   (state: ReduxStore) => state.user.adminPermission
  // );
  // console.log({ listAdminPermission });

  // const currentAdminPermission = useMemo(() => {
  //   if (!listAdminPermission) return;

  //   const listPermission = Object.keys(listAdminPermission).filter((item) => {
  //     return (
  //       listAdminPermission[item as keyof typeof listAdminPermission] ===
  //         ADMIN_PERMISSION.Edit ||
  //       listAdminPermission[item as keyof typeof listAdminPermission] ===
  //         ADMIN_PERMISSION.View
  //     );
  //   });

  //   return listPermission;
  // }, [listAdminPermission]);

  // console.log({ currentAdminPermission });
  const MerchantAndStoreManagement = [
    PATHS.merchant(),
    PATHS.merchantDetail(),
    PATHS.createMerchant(),
    PATHS.consumer(),
    PATHS.store(),
    PATHS.storeDetail(),
    PATHS.storeOnboardDetail(),
    PATHS.storeUpdateDetail(),
    PATHS.editStore(),
    PATHS.createStore(),
    PATHS.adminEditOnboardStoreDetail(),
    PATHS.adminEditUpdateStoreDetail(),
  ];

  const PlatformManagement = [
    PATHS.dashboardConfig(),
    PATHS.configMenu(),
    PATHS.configFeaturedRestaurants(),
    PATHS.configTopSellingDishes(),
    PATHS.categoryAndAttribute(),
    PATHS.categoryDetail(),
    PATHS.report(),
    PATHS.reportDetail(),
    PATHS.reportMerchantDetail(),
  ];

  const SystemSetup = [
    PATHS.parameters(),
    PATHS.emailTemplate(),
    PATHS.emailTemplateDetail(),
    PATHS.platformFeeManagement(),
  ];

  const FinanceManagement = [
    PATHS.merchant(),
    PATHS.merchantDetail(),
    PATHS.createMerchant(),
    PATHS.reconciliation(),
    PATHS.reconciliationDetail(),
    PATHS.reconciliationMerchantByDate(),
    PATHS.reconciliationOrderByDate(),
    PATHS.reconciliationStoreByDate(),
    PATHS.refund(),
    PATHS.refundDetail(),
    PATHS.report(),
    PATHS.reportDetail(),
    PATHS.reportMerchantDetail(),
    PATHS.listOrders(),
  ];

  const OperatorManagement = [
    PATHS.merchant(),
    PATHS.merchantDetail(),
    PATHS.createMerchant(),
  ];

  const CustomerSupport = [
    PATHS.listOrders(),
    PATHS.refund(),
    PATHS.refundDetail(),
  ];

  const UserManagement = [PATHS.role(), PATHS.member()];

  if (!isLogined) {
    return (
      <Redirect
        to={{
          pathname: PATHS.login(),
        }}
      />
    );
  }

  return <Route {...props} />;
};
