import { Form, FormItemProps, Input } from "antd";
import { TextAreaProps } from "antd/lib/input";
import React from "react";

interface Props extends Omit<TextAreaProps, "name"> {
  label: React.ReactNode;
  rules?: FormItemProps["rules"];
  textareaClassName?: string;
  name: string;
}

const CustomTextarea: React.FC<Props> = (props) => {
  const {
    className,
    textareaClassName,
    name,
    label,
    rules,
    rows,
    ...textareaProps
  } = props;

  return (
    <Form.Item name={name} rules={rules} label={label} labelCol={{ span: 24 }}>
      <Input.TextArea rows={rows || 4} {...textareaProps} name={name} />
    </Form.Item>
  );
};

export default CustomTextarea;
