import { UserReduxStore } from "src/types/globalStore";
import {
  SET_ADMIN_PERMISSION,
  SET_ADMIN_INFORMATION,
  SET_ADMIN_ROLE_ID,
} from "../constants/user";
import { isHavePermissionToEdit, isHavePermissionToView } from "src/helpers";

const initialState: UserReduxStore = {
  adminPermission: null,
  adminInformation: null,
  "user-management-permission": false,
  "dashboard-config-permission": false,
  "parameters-permission": false,
  "tri-member-permission": false,
  "finances-permission": false,
  "operations-permission": false,
  "customer-support-permission": false,
  "finances-view-permission": false,
  "operations-view-permission": false,
  "director-permission": false,
};

export const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ADMIN_PERMISSION:
      return {
        ...state,
        adminPermission: action.payload,
        "user-management-permission": isHavePermissionToEdit(
          action.payload["user-management"]
        ),

        "dashboard-config-permission": isHavePermissionToEdit(
          action.payload["dashboard-config"]
        ),

        "parameters-permission": isHavePermissionToEdit(
          action.payload.parameters
        ),

        "tri-member-permission": isHavePermissionToEdit(
          action.payload["tri-member"]
        ),

        "finances-permission": isHavePermissionToEdit(action.payload.finances),
        "operations-permission": isHavePermissionToEdit(
          action.payload.operations
        ),

        "finances-view-permission": isHavePermissionToView(
          action.payload.finances
        ),
        "operations-view-permission": isHavePermissionToView(
          action.payload.operations
        ),
        "customer-support-permission": isHavePermissionToEdit(
          action.payload["customer-support"]
        ),
        "director-permission": isHavePermissionToEdit(
          action.payload["director"]
        ),
      };

    case SET_ADMIN_INFORMATION:
      return {
        ...state,
        adminInformation: action.payload,
      };

    case SET_ADMIN_ROLE_ID:
      const newState = { ...state };
      if (!newState.adminInformation) {
        return state;
      }
      newState.adminInformation.role_id = action.payload;

      return { ...newState };
    default:
      return state;
  }
};
