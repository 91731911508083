import { FormMap } from "src/types/form-map";

export const MerchantLevel: FormMap[] = [
  {
    label: "Merchant ID",
    formName: "merchantId",
    placeholder: "Enter Merchant ID",
    rules: [
      {
        required: true,
        message: "This field is required",
        whitespace: false,
      },
    ],

    type: "hidden",
    maxLength: 120,
    readonly: true,
  },
  {
    label: "Merchant ID",
    formName: "membershipId",
    placeholder: "Enter Merchant ID",
    rules: [
      {
        required: true,
        message: "This field is required",
        whitespace: false,
      },
    ],

    type: "text",
    maxLength: 120,
    readonly: true,
  },
  {
    label: "Platform Fee (S$)",
    formName: "platformFee",
    placeholder: "Enter Platform Fee",
    rules: [
      {
        required: false,
        message: "This field is required",
        whitespace: false,
      },
    ],
    type: "number",
    isDecimal: true,
  },
  {
    label: "Mark Up (%)",
    formName: "markUp",
    placeholder: "Enter Mark Up",
    rules: [
      {
        required: false,
        message: "This field is required",
        whitespace: false,
      },
    ],
    type: "number",
    isDecimal: true,
  },
  {
    label: "Delivery Fee Discount (S$)",
    formName: "deliveryFeeDiscount",
    placeholder: "Enter Delivery Fee Discount",
    rules: [
      {
        required: false,
        message: "This field is required",
        whitespace: false,
      },
    ],
    type: "number",
    isDecimal: true,
  },
  {
    label: "Delivery Fee Mark Up",
    formName: "deliveryFeeMarkUp",
    placeholder: "Enter Delivery Fee Mark Up",
    rules: [
      {
        required: false,
        message: "This field is required",
        whitespace: false,
      },
    ],
    type: "number",
    isDecimal: true,
  },
  {
    label: "Delivery Fee Discount Lv2",
    formName: "deliveryFeeDiscountLevelTwo",
    placeholder: "Enter Delivery Fee Discount Lv2",
    rules: [
      {
        required: false,
        message: "This field is required",
        whitespace: false,
      },
    ],
    type: "number",
    isDecimal: true,
  },
  {
    label: "Delivery Fee Discount Lv2 Threshold",
    formName: "deliveryFeeDiscountLevelTwoThreshold",
    placeholder: "Enter Delivery Fee Discount Lv2 Threshold",
    rules: [
      {
        required: false,
        message: "This field is required",
        whitespace: false,
      },
    ],
    type: "number",
    isDecimal: true,
  },
  {
    label: "Discount (%)",
    formName: "discount",
    placeholder: "Enter Discount",
    rules: [
      {
        required: false,
        message: "This field is required",
        whitespace: false,
      },
    ],
    type: "number",
    isDecimal: true,
  },
  {
    label: "Max Order (S$)",
    formName: "maxOrder",
    placeholder: "Enter Max Order",
    rules: [
      {
        required: false,
        message: "This field is required",
        whitespace: false,
      },
    ],
    type: "number",
    isDecimal: true,
  },
  {
    label: "Pickup Discount (%)",
    formName: "pickupDiscount",
    placeholder: "Enter Pick Discount",
    rules: [
      {
        required: false,
        message: "This field is required",
        whitespace: false,
      },
    ],
    type: "number",
    isDecimal: true,
  },
];

export const categoryName = {
  platformFee: "Platform Fee",
  markUp: "Mark Up",
  deliveryFeeDiscount: "Delivery Fee Discount",
  deliveryFeeMarkUp: "Delivery Fee MarkUp",
  discount: "Discount",
  maxOrder: "Max Order",
  pickupDiscount: "Pickup Discount",
  deliveryFeeDiscountLevelTwo: "Delivery Fee Discount Level 2",
  deliveryFeeDiscountLevelTwoThreshold:
    "Delivery Fee Discount Level 2 Threshold",
};

export const categoryFormName = {
  "Platform Fee": "platformFee",
  "Mark Up": "markUp",
  "Delivery Fee Discount": "deliveryFeeDiscount",
  "Delivery Fee MarkUp": "deliveryFeeMarkUp",
  Discount: "discount",
  "Max Order": "maxOrder",
  "Pickup Discount": "pickupDiscount",
  "Delivery Fee Discount Level 2": "deliveryFeeDiscountLevelTwo",
  "Delivery Fee Discount Level 2 Threshold":
    "deliveryFeeDiscountLevelTwoThreshold",
};

export const StoreLevel: FormMap[] = [
  {
    label: "Platform Fee (S$)",
    formName: "platformFee",
    placeholder: "Enter Platform Fee",
    rules: [
      {
        required: false,
        message: "This field is required",
        whitespace: false,
      },
    ],
    type: "number",
    isDecimal: true,
  },
  {
    label: "Mark Up (%)",
    formName: "markUp",
    placeholder: "Enter Mark Up",
    rules: [
      {
        required: false,
        message: "This field is required",
        whitespace: false,
      },
    ],
    type: "number",
    isDecimal: true,
  },
  {
    label: "Delivery Fee Discount (S$)",
    formName: "deliveryFeeDiscount",
    placeholder: "Enter Delivery Fee Discount",
    rules: [
      {
        required: false,
        message: "This field is required",
        whitespace: false,
      },
    ],
    type: "number",
    isDecimal: true,
  },
  {
    label: "Delivery Fee Discount Lv2",
    formName: "deliveryFeeDiscountLevelTwo",
    placeholder: "Enter Delivery Fee Discount Level 2",
    rules: [
      {
        required: false,
        message: "This field is required",
        whitespace: false,
      },
    ],
    type: "number",
    isDecimal: true,
  },
  {
    label: "Delivery Fee Discount Lv2 Threshold",
    formName: "deliveryFeeDiscountLevelTwoThreshold",
    placeholder: "Enter Delivery Fee Discount Level 2 Threshold",
    rules: [
      {
        required: false,
        message: "This field is required",
        whitespace: false,
      },
    ],
    type: "number",
    isDecimal: true,
  },
  {
    label: "Discount (%)",
    formName: "discount",
    placeholder: "Enter Discount",
    rules: [
      {
        required: false,
        message: "This field is required",
        whitespace: false,
      },
    ],
    type: "number",
    isDecimal: true,
  },
  {
    label: "Pickup Discount (%)",
    formName: "pickupDiscount",
    placeholder: "Enter Pick Discount",
    rules: [
      {
        required: false,
        message: "This field is required",
        whitespace: false,
      },
    ],
    type: "number",
    isDecimal: true,
  },
  {
    label: "StoreId",
    formName: "storeId",
    placeholder: "Enter Pick Discount",
    rules: [
      {
        required: false,
        message: "This field is required",
        whitespace: false,
      },
    ],
    type: "hidden",
    isDecimal: false,
  },
];
