import { Breadcrumb, Layout, Menu, MenuProps } from "antd";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { LogoTrifood, LogoTrifoodSmall } from "src/assets/icons";
import { ADMIN_PERMISSION } from "src/constants";
import { handleNavigateBreadCrumb } from "src/helpers/breadcrumb";
import {
  getStorageJwtToken,
  hasStorageJwtToken,
  removeStorageJwtToken,
  removeStorageRefreshToken,
} from "src/helpers/storage";
import useInitTencentMessages from "src/hooks/useTencentMessages";
import { MemberService } from "src/services/membere-service";
import { NotificationServices } from "src/services/notification-service";
import { setAdminPermission } from "src/store/actions/user";
import { fetchListUnread } from "src/store/reducers/notification";
import { ReduxStore } from "src/types/globalStore";
import {
  DEEP_MENU_ITEMS,
  MENU_ITEMS,
  MENU_KEYS,
} from "../../constants/sidebar";
import "./styles.scss";
import { PATHS } from "src/constants/paths";

const { Header, Content, Sider } = Layout;

interface ILayoutProps {
  children?: React.ReactNode;
}

const LayoutDefault: React.FC<ILayoutProps> = ({ children }) => {
  const [tabNoti, setTabNoti] = React.useState<NotificationTabs>({
    pendingApprovalRequest: 0,
    pendingStoreOnboardRequest: 0,
    pendingStoreApprovalRequest: 0,
    pendingProducts: 0,
    ongoingMerchantOnboard: 0,
    pendingMembershipUpgradeRequest: 0,
  });
  const [collapsed, setCollapsed] = React.useState(false);
  const accessToken = getStorageJwtToken();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const listAdminPermission = useSelector(
    (state: ReduxStore) => state.user.adminPermission
  );
  const userInformation = useSelector(
    (state: ReduxStore) => state.user.adminInformation
  );

  useInitTencentMessages(userInformation?.id);
  const memberService = new MemberService();
  const notiService = new NotificationServices();

  const path = location.pathname.split("/")[1];
  const { search } = location;
  const adminRole = useSelector(
    (state: ReduxStore) => state.user.adminInformation?.role_id
  );

  async function handleGetAdminPermission() {
    if (adminRole) {
      try {
        const { data } = await memberService.getRoleDetail(adminRole);

        dispatch(setAdminPermission(data.authorities));
      } catch (error) {
        console.log(error);
      }
    }
  }

  async function getCountNotiForTabs() {
    try {
      const { data } = await notiService.getCountNotiForAllTabs();
      setTabNoti(data);
    } catch (error) {}
  }

  useEffect(() => {
    handleGetAdminPermission();
  }, [adminRole]);

  useEffect(() => {
    if (hasStorageJwtToken()) {
      dispatch(fetchListUnread());

      if (
        pathname === PATHS.merchant() ||
        pathname === PATHS.store() ||
        pathname === PATHS.productManagement()
      ) {
        getCountNotiForTabs();
      }
    }
  }, [accessToken, pathname]);

  const itemBreadcrumb = useMemo(() => {
    const match = DEEP_MENU_ITEMS.find((item) => item.key === path);
    if (path === "") {
      return [""];
    }
    if (path === MENU_KEYS.MERCHANT) {
      if (location.pathname.split("/").length === 3) {
        return ["User Management", "Merchant", "Merchant Details"];
      }
      if (location.pathname.split("/").length === 4) {
        return ["User Management", "Merchant", "Onboard request"];
      }
    }

    if (path === MENU_KEYS.REPORT) {
      if (location.pathname.split("/").length === 3) {
        return ["List Order", "Order Details"];
      }
      if (location.pathname.split("/").length === 4) {
        return ["Report", "Merchant"];
      }
      return ["Report"];
    }

    if (path === MENU_KEYS.CATEGORY_AND_ATTRIBUTE) {
      if (location.pathname.split("/").length === 4) {
        return ["Category & Attribute", "Sub Category"];
      }
    }

    if (path === MENU_KEYS.MERCHANT_LEVEL) {
      return ["Merchant & Store Management", "Merchant Level"];
    }

    if (path === MENU_KEYS.PRODUCT_MANAGEMENT) {
      return ["Merchant & Store Management", "Product Management"];
    }

    if (path === MENU_KEYS.RECONCILIATION) {
      return ["Merchant & Store Management", "Settlement Report"];
    }

    if (path === MENU_KEYS.CREATE_STORE) {
      return ["Create store"];
    }

    if (path === MENU_KEYS.CREATE_MERCHANT) {
      return ["Create merchant"];
    }

    if (path === MENU_KEYS.CREATE_PRODUCT) {
      return ["Create product"];
    }

    if (path === MENU_KEYS.EDIT_PRODUCT) {
      return ["Edit product"];
    }

    if (path === MENU_KEYS.EDIT_STORE) {
      return ["Edit store"];
    }

    if (path === MENU_KEYS.PLATFORM_FEE_MANAGEMENT) {
      return ["Platform Fee Management"];
    }

    if (path === MENU_KEYS.REFUND) {
      return ["Refund"];
    }

    if (path === MENU_KEYS.LISTORDER) {
      return ["List Order"];
    }
    if (match) {
      // level1
      return [match.name];
    } else {
      const isChild = DEEP_MENU_ITEMS.find((item) => {
        return item.children.find((sub) => sub.key === path);
      });
      const child = isChild?.children.find((sub) => sub.key === path);

      if (isChild) {
        return [isChild.name, child?.label];
      } else {
        return ["Not found"];
      }
    }
  }, [path, location.pathname]);

  const goToThePage = async (page: string) => {
    history.push(`${page}`);
  };

  function getNoti(label: string) {
    switch (label) {
      case "Merchant":
        return (
          tabNoti.ongoingMerchantOnboard +
          tabNoti.pendingApprovalRequest +
          tabNoti.pendingMembershipUpgradeRequest
        );
      case "Store":
        return (
          tabNoti.pendingStoreOnboardRequest +
          tabNoti.pendingStoreApprovalRequest
        );
      case "Product Management":
        return tabNoti.pendingProducts;

      default:
        return 0;
    }
  }

  const SIDEBAR_MENU_ITEMS: MenuProps["items"] = [
    {
      icon: MENU_ITEMS.MERCHANT_AND_STORE_MANAGEMENT.icon,
      label: <>{MENU_ITEMS.MERCHANT_AND_STORE_MANAGEMENT.label}</>,
      key: MENU_ITEMS.MERCHANT_AND_STORE_MANAGEMENT.key,
      children: MENU_ITEMS.MERCHANT_AND_STORE_MANAGEMENT.children.map(
        (item) => {
          return {
            key: item.key,
            label: item.label,
            children: item.children?.map((subItem) => {
              if (
                subItem.label === "Merchant" ||
                subItem.label === "Store" ||
                subItem.label === "Product Management"
              ) {
                return {
                  key: subItem.key,
                  label: (
                    <>
                      {subItem.label}
                      {getNoti(subItem.label) > 0 && (
                        <span className="noti">
                          <span>{getNoti(subItem.label)}</span>
                        </span>
                      )}
                    </>
                  ),
                  onClick: () => goToThePage(subItem.path),
                };
              } else {
                return {
                  key: subItem.key,
                  label: subItem.label,
                  onClick: () => goToThePage(subItem.path),
                };
              }
            }),
          };
        }
      ),
    },
    {
      icon: MENU_ITEMS.SETTLEMENT_REPORT.icon,
      label: MENU_ITEMS.SETTLEMENT_REPORT.label,
      key: MENU_ITEMS.SETTLEMENT_REPORT.key,
      children: MENU_ITEMS.SETTLEMENT_REPORT.children.map((item) => {
        return {
          key: item.key,
          label: item.label,
          onClick: () => goToThePage(item.path),
        };
      }),
    },
    {
      icon: MENU_ITEMS.PLATFORM_MANAGEMENT.icon,
      label: MENU_ITEMS.PLATFORM_MANAGEMENT.label,
      key: MENU_ITEMS.PLATFORM_MANAGEMENT.key,
      children: MENU_ITEMS.PLATFORM_MANAGEMENT.children.map((item) => {
        return {
          key: item.key,
          label: item.label,
          onClick: () => goToThePage(item.path),
        };
      }),
    },
    {
      icon: MENU_ITEMS.TRI_MEMBER.icon,
      label: MENU_ITEMS.TRI_MEMBER.label,
      key: MENU_ITEMS.TRI_MEMBER.key,
      children: MENU_ITEMS.TRI_MEMBER.children.map((item) => {
        return {
          key: item.key,
          label: item.label,
          onClick: () => goToThePage(item.path),
        };
      }),
    },
    {
      icon: MENU_ITEMS.REFUND.icon,
      label: MENU_ITEMS.REFUND.label,
      key: MENU_ITEMS.REFUND.key,
      children: MENU_ITEMS.REFUND.children.map((item) => {
        return {
          key: item.key,
          label: item.label,
          onClick: () => goToThePage(item.path),
        };
      }),
    },
    {
      icon: MENU_ITEMS.SYSTEM_SETUP.icon,
      label: MENU_ITEMS.SYSTEM_SETUP.label,
      key: MENU_ITEMS.SYSTEM_SETUP.key,
      children: MENU_ITEMS.SYSTEM_SETUP.children.map((item) => {
        return {
          key: item.key,
          label: item.label,
          onClick: () => goToThePage(item.path),
        };
      }),
    },
    {
      icon: MENU_ITEMS.LOGOUT.icon,
      label: MENU_ITEMS.LOGOUT.label,
      key: MENU_ITEMS.LOGOUT.key,
      onClick: () => {
        removeStorageJwtToken();
        removeStorageRefreshToken();
        history.push("/login");
      },
    },
  ];

  const getSideBarMenu = useMemo(() => {
    if (!listAdminPermission) return SIDEBAR_MENU_ITEMS;

    const listNoPermission = Object.keys(listAdminPermission).filter((item) => {
      return (
        listAdminPermission[item as keyof typeof listAdminPermission] ===
        ADMIN_PERMISSION.NoPermission
      );
    });

    const newSideBarMenu = SIDEBAR_MENU_ITEMS.filter((item) => {
      const key = item!.key?.toString() || " ";
      return !listNoPermission.includes(key);
    });

    return newSideBarMenu;
  }, [listAdminPermission, tabNoti]);

  const handleClick = (item: string) => {
    handleNavigateBreadCrumb(path, item, search, history, location);
  };

  return (
    <>
      {hasStorageJwtToken() ? (
        <div id="layout">
          <Layout>
            <Sider
              width={"285px"}
              className="site-layout-background"
              collapsible
              collapsed={collapsed}
              onCollapse={() => setCollapsed(!collapsed)}
            >
              <Header className="header">
                <div className="logo">
                  <img
                    style={{ width: collapsed ? "50px" : "auto" }}
                    src={collapsed ? LogoTrifoodSmall : LogoTrifood}
                    alt="login-background"
                  />
                </div>
              </Header>
              <Menu
                selectedKeys={[pathname]}
                mode="inline"
                defaultOpenKeys={[
                  "user-management",
                  "merchant-management",
                  "store-management",
                ]}
                style={{ height: "100%", borderRight: 0 }}
                items={getSideBarMenu}
              />
            </Sider>
            <Layout>
              <Header className="header">
                <div className="other">
                  <h3 style={{ color: "white", textAlign: "right" }}>
                    Hello, {userInformation?.first_name}
                  </h3>
                </div>
              </Header>
              <Breadcrumb style={{ margin: "16px 0", padding: "0px 24px" }}>
                <Breadcrumb.Item
                  className="breadcrumb"
                  onClick={() => history.push("/")}
                >
                  Home
                </Breadcrumb.Item>
                {itemBreadcrumb.map((item) => (
                  <Breadcrumb.Item
                    className="breadcrumb"
                    key={item}
                    onClick={() => handleClick(item as any)}
                  >
                    {item}
                  </Breadcrumb.Item>
                ))}
              </Breadcrumb>
              <Content style={{ padding: "0 24px 24px" }}>{children}</Content>
            </Layout>
          </Layout>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default LayoutDefault;
