import { Badge, List, Pagination } from "antd";
import dayjs from "dayjs";
import { cloneDeep, keys } from "lodash";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import TridentityPageHeader from "src/components/02.page-header";
import useTencentNotifications from "src/hooks/useTencentNotifications";
import CardContent from "src/routes/components/CardContent";
import "./styles.scss";

export enum AdminNotificationEventName {
  CategoryCreated = `CATEGORY_CREATED`,
  CategoryUpdated = `CATEGORY_UPDATED`,
  CategoryDeleted = `CATEGORY_DELETED`,
  ProductHidden = `PRODUCT_HIDDEN`,
  ProductUnhidden = `PRODUCT_UNHIDDEN`,
  ConsumerDeactivated = `CONSUMER_DEACTIVATED`,
  ConsumerActivated = `CONSUMER_ACTIVATED`,
  MerchantDeactivated = `MERCHANT_DEACTIVATED`,
  MerchantActivated = `MERCHANT_ACTIVATED`,
  MerchantOnboardingAccepted = `MERCHANT_ONBOARDING_ACCEPTED`,
  MerchantOnboardingDeclined = `MERCHANT_ONBOARDING_DECLINED`,
  DashboardConfigUpdated = `DASHBOARD_CONFIG_UPDATED`,
  ParametersConfigUpdated = `PARAMETERS_CONFIG_UPDATED`,
}

const NotificationPage = () => {
  const {
    handleReadNotification,
    loadNotifications,
    metadata,
    page,
    params,
    handleChangePagination,
  } = useTencentNotifications();
  const listNotifications: Notification[] = useSelector(
    (state: any) => state.notification.notifications
  );

  useEffect(() => {
    loadNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleTitle = (item: Notification) => {
    const { content } = item;
    const { message } = content;
    let messageCLone = cloneDeep(message);
    const listKey = keys(content).filter((item) => item !== "message");
    let res;
    if (listKey.length > 0) {
      res = listKey.reduce((message: string, value: string) => {
        return (message as string)?.replace(
          `:${value}`,
          `<span style="color:#38745E ;  font-weight: ${
            !dayjs(item.read_at).isValid() ? "600" : "400"
          }">${content[value]}</span>`
        );
      }, messageCLone);
    } else {
      res = message;
    }

    return {
      __html: `<div style="font-size: 14px;color:"rgba(0, 0, 0, 0.85)"
        ; font-weight:"600" ; cursor: "pointer">${res}</div>`,
    };
  };

  if (!listNotifications) return <></>;
  return (
    <>
      <TridentityPageHeader title="Notification" />
      <CardContent>
        <div
          id="scrollableDiv"
          style={{
            height: `calc(100vh - 310px)`,
            overflow: "auto",
            padding: "0 16px",
          }}
        >
          <List
            dataSource={listNotifications}
            renderItem={(item, idx) => {
              const css =
                item.read_at === undefined || !dayjs(item.read_at).isValid()
                  ? {}
                  : { opacity: 0.6 };
              return (
                <List.Item
                  key={item.id}
                  onClick={() => handleReadNotification(item, idx)}
                  style={css}
                >
                  <List.Item.Meta
                    avatar={item.indexTable}
                    title={<div dangerouslySetInnerHTML={handleTitle(item)} />}
                    description={
                      <div className="date">
                        {dayjs(item?.notify_at).format("DD/MM/YYYY HH:mm")}
                      </div>
                    }
                  />
                  <div>
                    {(item.read_at === undefined ||
                      !dayjs(item.read_at).isValid()) && (
                      <Badge key={"blue"} color={"blue"} />
                    )}
                  </div>
                </List.Item>
              );
            }}
          />
        </div>
        <Pagination
          defaultCurrent={1}
          current={page}
          onChange={handleChangePagination}
          total={metadata["x-total-count"]}
          showSizeChanger={false}
        />
      </CardContent>
    </>
  );
};

export default NotificationPage;
