import { Button, Col, Form, Modal, Row, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useMemo, useState } from "react";
import TridentityPageHeader from "src/components/02.page-header";
import InputSearch from "src/components/07.inputs/InputSearch";
import SearchTable from "src/components/11.tables/SearchTable";
import useDataTable from "src/hooks/useDataTable";
import CardContent from "src/routes/components/CardContent";
import { merchantFeeService } from "src/services/merchantfee-service";
import "./style.scss";

import {
  DeleteOutlined,
  EditOutlined,
  SelectOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import FormMap from "src/components/18.form/FormMap";
import {
  format2Digit,
  merchantLevelFeeFormattedPercentages,
} from "src/constants";
import { MerchantLevel } from "src/constants/merchantFeeLevel";
import { PATHS } from "src/constants/paths";
import useCheckIsFormEdit from "src/hooks/useCheckIsFormEdit";

const MerchantLevelPage = () => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const {
    dataSrc,
    fetchData,
    metadata,
    onChangeTable,
    loading,
    params,
    setParams,
  } = useDataTable(merchantFeeService.getListMerchantHaveConfigFee);
  const { isAnyFormChange, handleCheckIsFieldChange, setIsAnyFormChange } =
    useCheckIsFormEdit(form);
  const dataTable = useMemo(() => {
    if (!dataSrc) return [];
    return dataSrc.map((item: merchantInListFeeLevel) => {
      return {
        merchantId: item.merchantId || 0,
        membershipId: item.merchant.membershipId || 0,
        merchantName: item.merchant.name || 0,
        platformFee: item.platformFee || 0,
        markUp: item.markUp || 0,
        deliveryFeeDiscount: item.deliveryFeeDiscount || 0,
        discount: item.discount || 0,
        maxOrder: item.maxOrder || 0,
        pickupDiscount: item.pickupDiscount || 0,
        deliveryFeeDiscountLevelTwoThreshold:
          item.deliveryFeeDiscountLevelTwoThreshold || 0,
        deliveryFeeDiscountLevelTwo: item.deliveryFeeDiscountLevelTwo || 0,
        deliveryFeeMarkUp: item.deliveryFeeMarkUp || 0,
      };
    });
  }, [dataSrc]);

  useEffect(() => {
    fetchData(params);
  }, [params]);

  const columns: ColumnsType<any> = [
    {
      title: "No",
      width: 60,
      render: (value, record, index) =>
        index + 1 + params.perPage * (params.page - 1),
    },
    {
      title: "Merchant ID",
      key: "merchantId",
      width: 200,
      dataIndex: "merchantId",
      render: (value, record, index) => (
        <Link to={PATHS.merchantFeeDetail().replace(":id", value)}>
          {record.membershipId}
        </Link>
      ),
    },

    {
      title: "Merchant Name",
      key: "merchantName",
      dataIndex: "merchantName",
      render: (value, record, index) => <>{value}</>,
    },

    {
      title: "Platform Fee",
      key: "platformFee",
      dataIndex: "platformFee",
      render: (value, record, index) => <>S${format2Digit(value)}</>,
    },
    {
      title: "Mark Up",
      key: "markUp",
      dataIndex: "markUp",
      render: (value, record, index) => (
        <>{merchantLevelFeeFormattedPercentages(value)}</>
      ),
    },
    {
      title: "Delivery Fee Discount",
      key: "deliveryFeeDiscount",
      dataIndex: "deliveryFeeDiscount",
      render: (value, record, index) => <>S${format2Digit(value)}</>,
    },
    {
      title: "Delivery Fee Mark Up",
      key: "deliveryFeeMarkUp",
      dataIndex: "deliveryFeeMarkUp",
      render: (value, record, index) => <>{value}%</>,
    },
    {
      title: "Delivery Fee Discount Lv2",
      key: "deliveryFeeDiscountLevelTwo",
      dataIndex: "deliveryFeeDiscountLevelTwo",
      render: (value, record, index) => <>S${format2Digit(value)}</>,
    },
    {
      title: "Delivery Fee Discount Lv2 Threshold",
      key: "deliveryFeeDiscountLevelTwoThreshold",
      dataIndex: "deliveryFeeDiscountLevelTwoThreshold",
      render: (value, record, index) => <>S${format2Digit(value)}</>,
    },
    {
      title: "Discount",
      key: "discount",
      dataIndex: "discount",
      render: (value, record, index) => (
        <>{merchantLevelFeeFormattedPercentages(value)}</>
      ),
    },
    {
      title: "Max Order",
      key: "maxOrder",
      dataIndex: "maxOrder",
      render: (value, record, index) => <>S${format2Digit(value)}</>,
    },
    {
      title: "Pickup Discount",
      key: "pickupDiscount",
      dataIndex: "pickupDiscount",
      render: (value, record, index) => (
        <>{merchantLevelFeeFormattedPercentages(value ? value : 0)}</>
      ),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render: (value, record, index) => (
        <>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => {
              setModalEdit(record);
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            danger
            type="link"
            onClick={() => setOnDelete(record.merchantId)}
          />
        </>
      ),
    },
  ];

  const columnsMerchantUnseted: ColumnsType<any> = [
    {
      title: "No",
      width: 60,
      render: (value, record, index) =>
        index + 1 + paramsMerchant.perPage * (paramsMerchant.page - 1),
    },
    {
      title: "Merchant ID",
      key: "id",
      dataIndex: "membershipId",
      render: (value, record, index) => (
        <Link to={PATHS.merchantFeeDetail().replace(":id", value)}>
          {value}
        </Link>
      ),
    },

    {
      title: "Merchant Name",
      key: "name",
      dataIndex: "name",
      render: (value, record, index) => <>{value}</>,
    },

    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      width: 100,
      render: (value, record, index) => (
        <>
          <Button
            type="link"
            icon={<SelectOutlined />}
            onClick={() => {
              setOpen(true);
              setOpenSelect(false);
              form.setFieldValue("merchantId", record.id);
              form.setFieldValue("membershipId", record.membershipId);
            }}
          />
        </>
      ),
    },
  ];

  async function setOnDelete(merchantId: string) {
    Modal.confirm({
      title: "Are you sure want to remove this merchant",
      onOk: async () => {
        try {
          await merchantFeeService.deleteMerchantParameter(merchantId);
          await fetchData({ ...params, page: 1 });
          message.success("Remove Merchant Success");
        } catch (error) {
          message.error("Remove merchant faild");
        }
      },
    });
  }

  function setModalEdit(record: any) {
    const bodyForm = {
      merchantId: record.merchantId,
      membershipId: record.membershipId,
      platformFee: record.platformFee.toString(),
      markUp: record.markUp.toString(),
      deliveryFeeDiscount: record.deliveryFeeDiscount.toString(),
      discount: record.discount.toString(),
      maxOrder: record.maxOrder.toString(),
      pickupDiscount: record.pickupDiscount
        ? record.pickupDiscount.toString()
        : "0",
      deliveryFeeDiscountLevelTwo:
        record.deliveryFeeDiscountLevelTwo.toString(),
      deliveryFeeDiscountLevelTwoThreshold:
        record.deliveryFeeDiscountLevelTwoThreshold.toString(),
      deliveryFeeMarkUp: record.deliveryFeeMarkUp.toString(),
    };

    form.setFieldsValue(bodyForm);
    setOpen(true);
  }

  function handleChangeSearch(value: any) {
    setParams({ ...params, page: 1, search: value.target.value });
  }

  function onCloseModal() {
    setOpen(false);
    setIsAnyFormChange([]);
    form.resetFields();
  }

  async function onFinishForm(values: any) {
    try {
      setLoadingSubmit(true);
      const body = {
        platformFee: values.platformFee ? Number(values.platformFee) : null,
        markUp: values.markUp ? Number(values.markUp) : null,
        deliveryFeeDiscount: values.deliveryFeeDiscount
          ? Number(values.deliveryFeeDiscount)
          : null,
        discount: values.discount ? Number(values.discount) : null,
        maxOrder: values.maxOrder ? Number(values.maxOrder) : null,
        pickupDiscount: values.pickupDiscount
          ? Number(values.pickupDiscount)
          : null,
        deliveryFeeDiscountLevelTwoThreshold:
          values.deliveryFeeDiscountLevelTwoThreshold
            ? Number(values.deliveryFeeDiscountLevelTwoThreshold)
            : null,
        deliveryFeeDiscountLevelTwo: values.deliveryFeeDiscountLevelTwo
          ? Number(values.deliveryFeeDiscountLevelTwo)
          : null,
        deliveryFeeMarkUp: values.deliveryFeeMarkUp
          ? Number(values.deliveryFeeMarkUp)
          : null,
      };

      const result = await merchantFeeService.addMerchantInToListMerchantFee(
        values.merchantId,
        body
      );
      await fetchData(params);
      onCloseModal();
      message.success("Add merchant success");
    } catch (error) {
      message.error("Add merchant fail");
      console.log(error);
    } finally {
      setLoadingSubmit(false);
    }
  }

  const [openSelect, setOpenSelect] = useState(false);
  const {
    dataSrc: dataSrcMerchant,
    fetchData: fetchDataMerchant,
    metadata: metadataMerchant,
    onChangeTable: onChangeTableMerchant,
    loading: loadingMerchant,
    params: paramsMerchant,
    setParams: setParamsMerchant,
  } = useDataTable(merchantFeeService.getListMerchantUnsetedConfigFee);

  async function handleFetchMerchantUnsetted(search: any) {
    setParamsMerchant({
      ...paramsMerchant,
      search: search.target.value,
      page: 1,
    });
  }

  const merchantFormMap = useMemo(() => {
    return MerchantLevel.map((item) => {
      form.setFieldsValue({
        platformFee: Number(form.getFieldValue("platformFee"))?.toFixed(2),
        markUp: Number(form.getFieldValue("markUp"))?.toFixed(1),
        deliveryFeeDiscount: Number(
          form.getFieldValue("deliveryFeeDiscount")
        )?.toFixed(2),
        discount: Number(form.getFieldValue("discount"))?.toFixed(1),
        maxOrder: Number(form.getFieldValue("maxOrder"))?.toFixed(2),
        pickupDiscount: Number(form.getFieldValue("pickupDiscount"))?.toFixed(
          1
        ),
        // deliveryFeeDiscountLevelTwo: Number(form.getFieldValue("deliveryFeeDiscountLevelTwo"))?.toFixed(2),
        // deliveryFeeDiscountLevelTwoThreshold: Number(form.getFieldValue("deliveryFeeDiscountLevelTwoThreshold"))?.toFixed(2),
        // deliveryFeeMarkUp: Number(form.getFieldValue("deliveryFeeMarkUp"))?.toFixed(1),
      });
      const value = form.getFieldValue(item.formName)
        ? form.getFieldValue(item.formName)
        : "";

      return {
        ...item,
        onChange: () => handleCheckIsFieldChange(item.formName, value),
      };
    });
  }, [open]);

  useEffect(() => {
    if (!openSelect) return;
    fetchDataMerchant(paramsMerchant);
  }, [paramsMerchant, openSelect]);
  return (
    <>
      <div className="platform-fee-management">
        <TridentityPageHeader title="Merchant Level" backIcon />
        <CardContent>
          <div className="transaction-search-form">
            <Row gutter={30}>
              <Col span={12}>
                <InputSearch
                  searchResultCount={metadata?.["x-total-count"] || 0}
                  placeholder="Search Merchant"
                  handleChangeSearch={handleChangeSearch}
                  debounceTime={800}
                />
              </Col>

              <Col
                span={12}
                style={{ justifyContent: "flex-end", display: "flex" }}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    setOpenSelect(true);
                  }}
                >
                  + Add merchant
                </Button>
              </Col>
            </Row>
          </div>
        </CardContent>
        <CardContent>
          <SearchTable
            columns={columns}
            bordered
            loading={loading}
            rowKey={"merchantId"}
            dataSource={dataTable}
            onChange={onChangeTable}
            totalItems={metadata?.["x-total-count"] || 0}
            current={metadata?.["x-page"]}
            scroll={{ x: 2000 }}
          />
        </CardContent>

        <Modal
          width={1000}
          open={openSelect}
          footer={<></>}
          onCancel={() => setOpenSelect(false)}
          title="Search Merchant"
        >
          <InputSearch
            searchResultCount={metadataMerchant?.["x-total-count"] || 0}
            placeholder="Search Merchant"
            handleChangeSearch={(search) => handleFetchMerchantUnsetted(search)}
            debounceTime={800}
          />
          <SearchTable
            columns={columnsMerchantUnseted}
            bordered
            loading={loadingMerchant}
            rowKey={"id"}
            dataSource={dataSrcMerchant}
            onChange={onChangeTableMerchant}
            totalItems={metadataMerchant?.["x-total-count"] || 0}
            current={metadataMerchant?.["x-page"]}
          />
        </Modal>

        <Form
          name="update-product"
          wrapperCol={{ span: 24 }}
          onFinish={onFinishForm}
          onError={(e) => console.log(e)}
          autoComplete="off"
          scrollToFirstError={true}
          form={form}
        >
          <Modal
            open={open}
            onCancel={onCloseModal}
            title="Add Merchant"
            footer={
              <div className="btn-group">
                <Button loading={loadingSubmit} onClick={onCloseModal}>
                  Cancel
                </Button>
                <div>
                  <Button
                    loading={loadingSubmit}
                    disabled={isAnyFormChange.length > 0 ? false : true}
                    type="primary"
                    className="btn-accept"
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Save changes
                  </Button>
                </div>
              </div>
            }
            width={500}
          >
            <FormMap
              arrayField={merchantFormMap}
              form={form}
              isFormFilled={false}
            />
          </Modal>
        </Form>
      </div>
    </>
  );
};

export default MerchantLevelPage;
