export const PHONE_NUMBER_PATTERN = /^\.*[6|8|9]\d{7}$/gm;
export const PHONE_NUMBER_VIETNAM = /^[3|5|7|8|9]{1}[0-9]{8,8}\b/g;
export const CODE_SINGAPORE = "+65";
export const CODE_SINGAPORE_OPEN = "+650";
export const COMPANY_NAME_PATTERN = /^[a-zA-Z0-9][a-zA-Z0-9.\s]*$/;
export const EMAIL_PATTERN = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; //  various domains allowed (gmail.com, yahoo.com ...)

export const handlePhoneNumber = (phone: string) => {
  const prefixLocation = phone.includes(CODE_SINGAPORE);
  if (prefixLocation) {
    if (phone.includes(CODE_SINGAPORE_OPEN)) {
      return phone.replace(CODE_SINGAPORE_OPEN, "");
    } else {
      return phone.replace(CODE_SINGAPORE, "");
    }
  } else {
    return phone;
  }
};

export function getCurrentPattern(region: string | undefined) {
  if (!region) return PHONE_NUMBER_PATTERN;

  switch (region) {
    case "+84":
      return PHONE_NUMBER_VIETNAM;
    default:
      return PHONE_NUMBER_PATTERN;
  }
}
