import { ServiceBase } from "./core/service-base";
import { PageQueryParams } from "./params-type";

export class MerchantFeeService extends ServiceBase {
  getListMerchantHaveConfigFee = async (params: PageQueryParams) => {
    const res = await this.get(`/admin/merchants/parameters`, params);
    return res;
  };

  addMerchantInToListMerchantFee = async (merchantId: string, body: any) => {
    const res = await this.put(
      `/admin/merchants/parameters/${merchantId}`,
      body
    );
    return res;
  };

  getListMerchantUnsetedConfigFee = async (params: PageQueryParams) => {
    const res = await this.get(
      "/admin/merchants/parameters/unseted-merchants",
      params
    );
    return res;
  };

  getDetailMerchantConfigFee = async (merchantId: string) => {
    const res = await this.get(`/admin/merchants/parameters/${merchantId}`);
    return res;
  };

  getUnsetedStoreFee = async (merchantId: string, params: PageQueryParams) => {
    const res = await this.get(
      `/admin/merchants/${merchantId}/unseted-parameter-stores`,
      params
    );
    return res;
  };

  getSetedStoreFee = async (merchantId: string, params: PageQueryParams) => {
    const res = await this.get(
      `/admin/merchants/${merchantId}/parameter-stores`,
      params
    );
    return res;
  };

  addStoreToFee = async (body: any) => {
    const res = await this.post("/admin/merchant-stores/parameters", body);
    return res;
  };

  editSetedStore = async (id: string, body: any) => {
    const res = await this.put(`/admin/merchant-stores/parameters/${id}`, body);
    return res;
  };

  deleteMerchantParameter = async (id: string) => {
    const res = await this.delete(`/admin/merchants/parameters`, id);
    return res;
  };

  deleteStoreParameter = async (id: string) => {
    const res = await this.delete(`/admin/merchant-stores/parameters`, id);
    return res;
  };
}

export const merchantFeeService = new MerchantFeeService();
